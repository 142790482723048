<template>
<div>
    <b-card>
  <div id="cd-circle">
    <div class="countdown">
      <div class="clock-count-container">
        <h1 class="clock-val">{{ demo2.days }}</h1>
      </div>
      <h4 class="clock-text">Days</h4>
    </div>
    <div class="countdown">
      <div class="clock-count-container">
        <h1 class="clock-val">{{ demo2.hours }}</h1>
      </div>
      <h4 class="clock-text">Hours</h4>
    </div>
    <div class="countdown">
      <div class="clock-count-container">
        <h1 class="clock-val">{{ demo2.minutes }}</h1>
      </div>
      <h4 class="clock-text">Mins</h4>
    </div>
    <div class="countdown">
      <div class="clock-count-container">
        <h1 class="clock-val">{{ demo2.seconds }}</h1>
      </div>
      <h4 class="clock-text">Sec</h4>
    </div>
  </div>
  </b-card>
   <div>
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2>
          Elite Emperors Whitelist coming Soon 
        </h2>
        <p class="mb-3">
          We're creating something awesome. Please subscribe to get notified when it's ready!
        </p>
         <b-img src="@/assets/images/banner/banner1.png" fluid alt="Responsive image" fluid-grow />
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import '@/assets/scss/custom-countdown.scss'
import {BCard,BCardTitle,BCardHeader,BCardBody,BImg} from "bootstrap-vue"
export default {
    components:{BCard,BCardTitle,BCardHeader,BCardBody,BImg},
    data() {
            return {
                code_arr: [],
                demo2: {
                    days: null,
                    hours: null,
                    minutes: null,
                    seconds: null
                }
            };
        },
        mounted() {
            this.set_timer_demo2();
        },
        methods: {
            toggleCode(name) {
                if (this.code_arr.includes(name)) {
                    this.code_arr = this.code_arr.filter(d => d != name);
                } else {
                    this.code_arr.push(name);
                }
            },
       
            set_timer_demo2() {
                let countDownDate = 1668753334000;
                let timer2 = setInterval(() => {
                    let now = new Date().getTime();
                    let distance = countDownDate - now;
                    this.demo2.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    this.demo2.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    this.demo2.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    this.demo2.seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    if (distance < 0) {
                        clearInterval(timer2);
                    }
                }, 1000);
            }
        }
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>